/// <reference path="DOMAttached.js" />
/// <reference path="TextResource.js" />
/// <reference path="UrlTemplate.js" />
/// <reference path="ProgressIndicatingControl.js" />
/// <reference path="gameUtils/GamesTextToSpeech.js" />

var GamesQuizInstance = null;

var GamesFlashQuiz = Class.create(DOMAttached, {
	initialize: function($super, el, options) {
		$super(el, options);		
		GamesQuizInstance = this;

		this.progressBar = $(this.options.progressBar);
		this.container = this.el.down('.questionContainer');
		this.message = this.el.down('span.message');
		this.level = -1;
		this.progress = 0;
		this.amount = -1;
		this.numberList = [];
		this.textList = [];
		this.medalType = this.options.medalType;

		this.questionSoundPaths = [];

		if (GenericSoundsInstance == null) {
			new GenericSounds(this.options.FeedbackSoundOn);
		}
		if (GamesTextToSpeechInstance == null) {
			new GamesTextToSpeech(this, this.options.ReadQuestionsOn && this.options.TextToSpeechEnabled, this.options.TextToSpeechEnabledByParent);
		}

		this.loadPage();
	},

	userAnswer: function(success) {
		this.success = success;
		GamesTextToSpeechInstance.removeTextToSpeechEffects();
		
		if (this.success) {
			var itemId = this.numberList[this.progress] | 0;
			this.progress++;

			this.setTaskText(new TextResource(this.options.trLoadingLevel).string());
			new Ajax.Request(new UrlTemplate(this.options.processAnswerUrl)
				.r('PLH_success', this.success)
				.r('PLH_Status', 2)
				.r('PLH_MedalType', this.medalType)
				.r('PLH_progress', this.progress)
				.r('PLH_itemId', itemId)
				.r('PLH_nquestion',-2).url, {
				method: 'get',
				onSuccess: this.onSuccess.bind(this),
			});
			
			this.updateProgressBar();
			if (this.progress < this.numberList.length) {
				GenericSoundsInstance.successSound(false, this.createAndReadAllTextToSpeech.bind(this));
				this.setToClick(this.numberList[this.progress], this.textList[this.progress]);
			} else { // level up
				GenericSoundsInstance.successSound();
				this.removeAllListeners();
			}

		} else {
			GenericSoundsInstance.failSound();
			this.handleWrongAnswer();
		}
	},

	updateProgressBar: function () { 
		this.progressBar.style.width = (this.progress / this.numberList.length * 100) + "%";
		var numberQuestionsLeft = this.numberList.length - this.progress;
		var questionsLeftText = numberQuestionsLeft === 1
			? this.options.questionsLeftTR_singular
			: this.options.questionsLeftTR.replace('{num}', numberQuestionsLeft);
		this.el.down('.questionsLeft').innerText = questionsLeftText;
	},

	/*
	 * This is called by ToggleQuestionReader when it turns on TTS.
	 * Don't remove or rename.
	 */
	createTextToSpeechElements: function () {
		var questionElement = {};

		questionElement.domElement = this.el.down('.question');
		questionElement.identifier = 'question';
		questionElement.soundUrl = this.questionSoundPaths[this.progress];
		questionElement.highlightFontColor = '#7F82D2';
		questionElement.defaultFontColor = this.getFontColor('.question');

		return [questionElement];
	},

	getBackgroundColor: function (className) {
		var element = this.el.down(className);
		if (typeof (element) !== 'undefined' && element !== null) {
			return $(element).getStyle('background-color');
		} else {
			return '';
		}
	},

	getFontColor: function (className) {
		var element = this.el.down(className);
		if (typeof (element) !== 'undefined' && element !== null) {
			return $(element).getStyle('color');
		} else {
			return '';
		}
	},

	handleWrongAnswer: function() {
		var itemId = this.numberList[this.progress] | 0;
		this.progress = 0;
		this.progressBar.style.width = "0";

		new Ajax.Request(new UrlTemplate(this.options.processAnswerUrl)
			.r('PLH_success', this.success)
			.r('PLH_Status', 2)
			.r('PLH_MedalType', this.medalType)
			.r('PLH_progress', this.progress)
			.r('PLH_itemId', itemId)
			.r('PLH_nquestion', -2).url, { 
			method: 'get', 
			onSuccess: this.onSuccess.bind(this), 
		});

		this.amount = -1;
		this.level = -1;
		this.resetUI();
		this.initFlashGame();
	},

	loadPage: function() {
		new Ajax.Request(new UrlTemplate(this.options.processAnswerUrl)
			.r('PLH_TimeNeeded', 1)
			.r('PLH_KeystrokesCorrect', 1)
			.r('PLH_KeystrokesIncorrect', 1)
			.r('PLH_success', true)
			.r('PLH_Status', 0)
			.r('PLH_MedalType', this.medalType)
			.r('PLH_progress', 0)
			.r('PLH_itemId', -1)
			.r('PLH_nquestion', -2).url, {
			method: 'get'
		});
	},

	initFlashGame: function(firstInstalation) {
		new Ajax.Request(new UrlTemplate(this.options.processAnswerUrl)
			.r('PLH_success', true)
			.r('PLH_Status', 1)
			.r('PLH_MedalType', this.medalType)
			.r('PLH_progress', this.progress)
			.r('PLH_itemId', -1)
			.r('PLH_nquestion', -2).url, {
			method: 'get',
			onSuccess: this.onInitSuccess.bind(this, firstInstalation)
		});
	},

	onInitSuccess: function(firstInstalation, req) {
		// We don't want to do initialization for a game when it was already closed (e.g., when user closed the lightbox with the game right after opening it)
		if(document.getElementById(this.el.id) == null) {
			return;
		}
		this.json = req.responseText.evalJSON();
		if (this.json.numberList.length == 0 || this.json.textList[0] == undefined) {
			this.progress = 0;
			this.initFlashGame();
			return;
		}
		this.level = this.json.level;
		this.progress = this.json.progress;
		this.amount = this.json.amount;
		this.numberList = this.json.numberList;
		this.textList = this.json.textList;
		this.questionSoundPaths = this.json.questionSoundPaths;	

		this.showPage();
		this.initGame(this.numberList[this.progress], this.textList[this.progress]);
		
		// We dispatch this event to then resize SVG in HtmlGame.vue
		window.dispatchEvent(new Event('flashGameInitialized'));

		if (window.getComputedStyle(document.getElementById("levelUpScreen")).display === 'none') {
			this.createAndReadAllTextToSpeech();
		}
	},
 
	// public - also used by ToggleQuestionReader!
	createAndReadAllTextToSpeech: function () {
		GamesTextToSpeechInstance.setTextToSpeechElements(this.createTextToSpeechElements());

		var _this = this;

		setTimeout(function () { GamesTextToSpeechInstance.readSpeechForAllElementsInOrder(); }.bind(_this), 1000);
	},

	handleProcessResult: function() {
		if (this.success) {
			if (this.json.numberList.length == 0 || this.json.textList[0] == undefined) {
				this.progress = 0;
				this.initFlashGame();
				return;
			}
			this.level = this.json.level;
			this.progress = 0;
			this.amount = this.json.amount;
			this.numberList = this.json.numberList;
			this.textList = this.json.textList;
			this.questionSoundPaths = this.json.questionSoundPaths;
			this.progressBar.style.width = "0";
			
			this.resetUI();
			this.setToClick(this.numberList[0], this.textList[0]);
			this.createAndReadAllTextToSpeech();
		}
	},

	handleNextPageLoad: function() {
		this.showProgressIndicator();
		new Ajax.Request(this.getPageLoadUrl(), {
			method: 'get',
		});
	},

	getPageLoadUrl: function() {
		return new UrlTemplate(this.options.loadUrl).url;
	},

	onSuccess: function(req) {
		try {
			this.json = req.responseText.evalJSON();
		} catch (e) {
			global.handleFailedAJAJRequest();
			return;
		}

		if (this.progress == this.level) {
			this.handleProcessResult(this.json);
		}
	},

	customLoadEvent: function(json) {
		throw new Error('wrong method');
	},
	
	showPage : function() {
	}
});

GamesFlashQuiz.addMethods(ProgressIndicatingControl);
